import { DistributiveOmit, PartialSome, PropagateExtraField, WithExtra, WithParam } from '../utils.ts'

/**
 * BASE TYPES
 */
export type ApiError = {
  classification: string
  fieldNames: string[]
  message: string
}[]

export type I18n<TYPE = string> = Partial<Record<Lang, TYPE>>

export const LANGS = ['ca', 'de', 'en', 'es', 'fr', 'it', 'nl', 'pt'] as const
export type Lang = (typeof LANGS)[number]
export const LOCALES = {
  ca: 'ca-ES',
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  nl: 'nl-NL',
  pt: 'pt-PT',
} as const
export type Locale = (typeof LOCALES)[Lang]

export type Pageable<RESPONSE> = RESPONSE & {
  meta: {
    limit: number
    next: string | null
    total_count: number
    /** not documented, boutique specific */
    offset?: number
  }
}

export type WithOrderFacets<RESPONSE> = RESPONSE & {
  facets?: {
    payment_methods: { value: string; count: number }[] | null
    rewards:
      | {
          value: {
            id: number
            title: I18n<string>
            description: I18n<string>
          }
          count: number
        }[]
      | null
    status: { value: string; count: number }[] | null
    tip: number
  }
}

/**
 * ACCOUNT
 */
export enum AccountType {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
  ASSOCIATION = 'association',
}

type TaxDeductibleRate = '66' | '75'

export type Account = {
  association_type: AssociationType
  birthday: string
  can_be_deleted: boolean
  country: string
  currency: string
  edit_url: string
  email: string
  entity_name: string
  fieldsets?: Fieldset[]
  first_name: string
  id: number
  image_signature?: ImageResource
  is_completed: boolean
  is_entrepreneur: boolean
  is_last?: boolean
  label?: string
  last_name: string
  latest_project?: Project
  legal_representative_firstname?: string
  legal_representative_job_title?: string
  legal_representative_lastname?: string
  nationality: string
  status: string
  tax_deductible_rate: TaxDeductibleRate
  tax_receipt_accreditation: boolean
  type: AccountType
  user_id: number
}

export type AccountList = Pageable<{ accounts: Account[] | null }>

export enum AccountStatus {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type Fieldset = {
  fields: Field[]
  label?: string // NOTE: Sync isn't global yet. Some older projets don't have a label.
  features?: string[] // NOTE: same issue
  error_messages?: string[] // NOTE: same issue
  name:
    | 'legal_representative'
    | 'legal_representative_documents'
    | 'bank_account'
    | 'organization_headquarters'
    | 'organization_identification'
    | 'organization_owners'
  status: AccountStatus
}

export type Field = {
  data:
    | {
        value: string
        status: AccountStatus
      }
    | {
        address1: string | null
        address2: string | null
        city: string | null
        country: string
        entity_name: string | null
        first_name: string
        id: string
        last_name: string
        postal_code: string | null
        state: null
        status: AccountStatus
        type: AccountType
      }
    | {
        file: null
        name: string
        purpose: null
        size: null
        status: AccountStatus
        type: string
      }
    | {
        account_number: string
        error_message: string | null
        id: string
        routing_number: string
        status: AccountStatus
      }

    // TODO: replace [] by real type
    | []
    | null
  name: string
}

export type AssociationType = 'basic' | 'public_utility' | 'help_for_people' | 'general_interest' | 'other'

export type CreateAccount = {
  association_type?: AssociationType
  birthday: string
  country: string
  currency: string
  email: string
  entity_name?: string
  first_name: string
  is_required_personal_number?: boolean
  label?: string
  last_name: string
  nationality: string
  return_url: string
  tax_receipt_accreditation?: boolean
  type: AccountType
}

export type UpdateAccount = {
  tax_receipt_accreditation?: boolean
}

/**
 * ADDRESS
 */

export enum AddressType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
  ASSOCIATION = 'association',
}

export type Address<EXTRA_FIELDS extends string = ''> = (EntityAddress | PersonalAddress) & {
  id: number
  phone_number: string
  user_id: number
} & WithExtra<{ has_shippings: { has_shippings: boolean } }, EXTRA_FIELDS>

export type Addresses<EXTRA_FIELDS extends string = ''> = {
  addresses: Address<EXTRA_FIELDS>[]
}

export type BaseAddress = {
  address1: string
  address2: string
  city: string
  country: string
  postal_code: string
  state: string
}

export type CreateAddress = PartialSome<
  DistributiveOmit<Address, 'id' | 'user_id' | 'has_shippings'>,
  'address2' | 'phone_number' | 'state'
>

type EntityAddress = BaseAddress & {
  entity_name: string
  first_name?: string
  last_name?: string
  type: AddressType.BUSINESS | AddressType.ASSOCIATION
}

type PersonalAddress = BaseAddress & {
  entity_name?: string
  first_name: string
  last_name: string
  type: AddressType.PERSONAL
}

export type UpdateAddress = Partial<DistributiveOmit<Address, 'id' | 'user_id' | 'has_shippings'>>

/**
 * ANALYTICS
 */
export enum AllowedAnalyticsTag {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
}

export type AnalyticsTag = {
  id: number
  tag: string
  type: AllowedAnalyticsTag
}

export type NewAnalyticsTag = {
  id: number
  tag: string
  type: AllowedAnalyticsTag
}

export type AnalyticsTags = {
  analytics: AnalyticsTag[]
}

export type CreateAnalyticsTag = {
  tag: string
  type: AllowedAnalyticsTag
}

/**
 * BILLING
 */

export type Invoice = {
  url: string
}

/**
 * CATEGORY & TAG
 */

export type Category = Omit<Tag, 'category'>

export type CategoryList = {
  categories: Category[]
}

/**
 * CHANNEL
 */

export type Channel = {
  absolute_url: string
  background: ImageResource | undefined
  bg_color: string
  cta_background: ImageResource | undefined
  description: I18n
  name: I18n
  partner: Partner
  user: PublicUser
}

export type ChannelList = {
  channels: Channel[]
}

/**
 * COMMENT
 */

export type Comment<IS_ROOT = false> = {
  comment: string
  id: number
  is_public: boolean
  submit_date: string
  submit_date_formatted: string
  user: PublicUser & { role: UserRole }
} & WithParam<true, IS_ROOT, { replies: Comment[]; replies_count: number }>

export type CommentList<IS_ROOT> = {
  comments: Comment<IS_ROOT>[]
}

export type CreateComment = {
  comment: string
}

/**
 * EXPORT
 */

export type Export = {
  created_at: string
  format: ExportFormat
  id: number
  project_id: number
  status: 'succeeded' | 'waiting' | 'processing' | 'failed'
  type: ExportType
  url: string
}

export type ExportFormat = 'csv' | 'xlsx'

export type ExportTabularStructure = {
  sheets: {
    view_type: ExportViewType
    sheet_name: string
    columns: {
      id?: string
      [k: string]: string | undefined
    }
  }[]
  type: ExportType
}

export type ExportFileType = 'tax_deduction_receipt'
export type ExportType =
  | 'orders'
  | 'tax_deduction'
  | 'tax_deduction_receipt'
  | 'project_subscriptions'
  | 'referrals'
  | 'project_fans'

export type ExportViewType =
  | 'view_by_cross_tab'
  | 'view_by_dropoff'
  | 'view_by_orders'
  | 'view_by_pickup'
  | 'view_by_rewards'
  | 'view_by_shipping_address'
  | 'view_by_subscription_payments'
  | 'view_by_tax_deduction'
  | 'view_subscriptions'
  | 'view_by_order_referrals'
  | 'view_by_referrals'
  | 'view_by_fans'

export type CreateExport = {
  date_end?: string | null
  date_start?: string | null
  file?: {
    type: ExportFileType
  }
  tabulars?: {
    format: ExportFormat
    exports: ExportTabularStructure[]
  }
}

/**
 * FAQs
 */

export type CreateFaq = {
  question: I18n
}

export type Faq = {
  answer_author: PublicUser
  answer: I18n
  id: number
  question_author: PublicUser
  question: I18n
}

export type FaqList = {
  faq: Faq[]
}

/**
 * DISCUSSION
 */

export type DiscussionAttachment = {
  created_at: string
  file: string
  id: number
  name: string
}

export type DiscussionMessage = {
  attachments: DiscussionAttachment[]
  body: string
  body_html: string
  id: number
  sender_deleted_at: string | null
  sender: PublicUser
  sent_at: string
  status: DiscussionMessageStatus
  thread_id: number
}

export enum DiscussionMessageStatus {
  DRAFT = 'draft',
  SENT = 'sent',
}

export type DiscussionThread = {
  created_at: string
  id: number
  latest_message: DiscussionMessage
  messages_count: number
  metadata: any
  recipients: PublicUser[]
  recipients_count: number
  sender_deleted_at: string | null
  sender_id: number
  status: DiscussionThreadStatus
  subject: string
  updated_at: string
}

export enum DiscussionThreadStatus {
  UNREAD = 'unread',
  READ = 'read',
  DELETED = 'deleted',
}

/**
 * IMAGES
 */

export type Avatar = {
  id: number
  name: string
  value: string
  versions: Record<string, ImageVersion>
}

export type CreateImage = {
  color?: string
  image: File
  lang: string
  type: ImageType
}

export type ImageResource = {
  color?: string
  extracted_colors?: { [id: string]: string }
  id: number
  lang: string
  name?: string
  type: string
  url?: string
  is_deletable?: boolean
  versions?: Record<string, ImageVersion>
}

export enum ImageType {
  AVATAR = 'avatar-draft',
  BACKGROUND = 'background',
  BACKGROUND_DRAFT = 'background-draft',
  MAIN = 'main',
  MAIN_DRAFT = 'main-draft',
  NEWS_DRAFT = 'news-draft',
  RETURN = 'return' /* bob specific */,
  REWARD = 'reward',
  SECONDARY = 'secondary',
  SHARE_DRAFT = 'share-draft',
  SHIPPING_LABEL = 'shipping-label' /* bob specific */,
  SIGNATURE = 'signature',
}

export type ImageVersion = {
  height: number | undefined
  url: string
  width: number | undefined
}

/**
 * LINK
 */

export type CreateLink = {
  url: string
}

export type Link = {
  crawlable: boolean
  followers_count: number
  id: number
  service_type: LinkServiceType
  title: I18n
  url: string
  views_count: number
  position: number
}

export type LinkServiceType =
  | 'bandcamp'
  | 'bluesky'
  | 'dailymotion'
  | 'discord'
  | 'email'
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'linktree'
  | 'mastodon'
  | 'soundcloud'
  | 'spotify'
  | 'threads'
  | 'tiktok'
  | 'tumblr'
  | 'twitch'
  | 'twitter'
  | 'vimeo'
  | 'website'
  | 'youtube'

/**
 * NEWS
 */

export type News<WITH_PROJECT = false, WITH_AUTHOR = false> = {
  absolute_url: string
  audience_type: NewsAudienceType
  comments_count: number
  content: I18n | null
  content_type: 'text' | 'image' | 'video'
  content_types: I18n<'text' | 'image' | 'video'>
  date_creation: string
  date_publication: string | null
  date_schedule?: string
  id: number
  image: I18n<ImageResource> | undefined
  permissions: {
    self: ('read' | 'update')[] | null
  }
  preview: I18n
  reserved: boolean
  resource_uri: string
  rewards: Reward[] | null
  slug: string
  status: NewsStatus
  title: I18n
  video: I18n<VideoResource> | undefined
} & WithParam<true, WITH_AUTHOR, { author: PublicUser | undefined }> &
  WithParam<true, WITH_PROJECT, { project: Project | undefined }>

export type NewsList<WITH_DETAIL = false> = {
  news: News<WITH_DETAIL, WITH_DETAIL>[] | null
}

export type NewsListPage = Pageable<NewsList>

export type NewsListPageWithFacets = WithNewsFacets<NewsListPage>

export enum NewsAudienceType {
  ALL = 'all',
  SUPPORTERS = 'supporters',
  FANS = 'fans',
  PAYING = 'paying-members',
}

export enum NewsStatus {
  ONLINE = 'online',
  WAITING = 'waiting',
  SCHEDULED = 'scheduled',
}

export type CreateNews = {
  audience_type?: NewsAudienceType
  content: I18n
  image_id?: I18n<number>
  reward_ids?: number[]
  title: I18n
  video_url?: I18n<string | null>
  status?: NewsStatus.SCHEDULED | NewsStatus.WAITING
  date_schedule?: string | null
}

export type WithNewsFacets<RESPONSE> = RESPONSE & {
  facets?: {
    public?: number
    content_types?: {
      count: number
      value: 'text' | 'image' | 'video'
    }[]
    rewards?: {
      count: number
      value: {
        id: number
        title: I18n<string>
        description: I18n<string>
      }
    }[]
  }
}

/**
 * ORDER
 */

export type CreateOrder = {
  dry_run?: boolean
  tip?: number
  payment_method: string
  pickup_point_id?: number
  return_url: string
  rewards?: RewardPayload[]
  country?: string
  delivery_type?: DeliveryType
  tracking?: { [property: string]: string } /* Undocumented on purpose, for internal use only */
} & (
  | { billing_address?: CreateAddress; billing_address_id?: undefined }
  | { billing_address?: undefined; billing_address_id?: number }
) &
  (
    | {
        shipping_address?: CreateAddress &
          (
            | {
                dropoff_id: number
                dropoff_name: string
                longitude: number
                latitude: number
              }
            | {
                dropoff_id?: undefined
                dropoff_name?: undefined
                longitude?: undefined
                latitude?: undefined
              }
          )
        shipping_address_id?: undefined
      }
    | { shipping_address?: undefined; shipping_address_id?: number }
  )

export enum OrderType {
  MONTHLY = 'monthly',
  SINGLE = 'single',
}

export enum OrderStatus {
  /* for check payments */
  PROCESSING = 'processing',
  /* order created, checkout redirected to kolkt, but no payment made yet */
  AWAITING_CONFIRMATION = 'awaiting-confirmation',
  /* order payed, not transfered to project owner yet */
  PAYMENT_COMPLETED = 'payment-completed',
  /* order payed, cancelled by backer (reimbursed) before transfer to project owner */
  CANCELLED = 'cancelled',
  /* order payed, transfered to project owner */
  PAYMENT_DONE = 'payment-done',
  /* error while transfering to project owner (pre-authorization or paypal) */
  PAYMENT_INVALID = 'payment-invalid',
  /* order payed, project failed, backer reimbursed */
  PAYMENT_REIMBURSED = 'payment-reimbursed',
  /* error during payment (3DS, mangopay error etc ...) */
  ERROR = 'error',
}

export type BillingAddressType = 'tax' | 'contact'

export type ShippingMode = 'home_delivery' | 'mondial_relay' | 'pickup_point'

export type Order<WITH_PROJECT = false, EXTRA_FIELDS extends string = ''> = {
  absolute_url: string | undefined
  bankwire?: {
    account_number: string
    owner_name: string
    routing_number: string
    wire_reference: string
  }
  billing_address: Address | undefined
  billing_address_required: boolean
  billing_address_type?: BillingAddressType
  created_at: string
  countries_available: string[]
  discount_total?: number
  edit_url: string | undefined
  error_code?: string
  first_in_subscription: boolean | undefined
  id: number
  initial_billing_address_required: boolean
  initial_contact_address_required: boolean
  initial_pickup_point_required: boolean
  initial_shipping_address_required: boolean
  initial_tax_address_required: boolean
  is_tip: boolean
  items?: OrderItem<PropagateExtraField<EXTRA_FIELDS, 'items'>>[]
  note: string | undefined
  order_shipping_total: number
  order_subtotal: number
  order_total: number
  subscription_total: number | undefined
  payment_method: PaymentMethod
  payment_url: string
  pickup_point?: PickupPoint | undefined
  pickup_point_required: boolean
  phone_number_required: boolean
  project_id: number
  provider?: 'stripe' | 'mangopay' | 'paypal' | 'check' | 'leetchi'
  refunded: boolean
  resource_uri: string
  shipping_address?:
    | (Address & {
        delivery_type?: 'mondial_relay' | 'home_delivery'
        latitude?: number
        longitude?: number
        dropoff_id?: string
        dropoff_name?: string
      })
    | undefined

  shipping_address_required: boolean
  shippings_available?: {
    pickup_points?: PickupPoint[]
    standard?: Record<string, number | undefined>
    mondial_relay?: Record<string, number | undefined>
  }
  initial_shippings_available?: {
    pickup_points?: PickupPoint[]
    standard?: Record<string, number | undefined>
    mondial_relay?: Record<string, number | undefined>
  }
  shipping_modes: ShippingMode[] | null
  shipping_status?: ShippingStatus | undefined
  shipping_type?: OrderShippingType | undefined
  status: OrderStatus
  tax_deductible: boolean
  tax_deductible_subtotal?: number
  tax_deductible_total?: number
  tax_receipt: boolean
  tax_receipt_url: string | undefined
  thanks_note?: I18n
  tip: number
  user: (Supporter & { email: string; socials?: UserSocials[] }) | undefined
  is_hidden?: boolean
  is_cancellable?: boolean
  private_note?: string /** not documented */
  renew_url?: string /** not documented */

  shopify_order_number?: number /** not documented, bob specific */
  shopify_order_id?: number /** not documented, bob specific */

  children: 'children' extends EXTRA_FIELDS
    ? ChildOrder<PropagateExtraField<Exclude<EXTRA_FIELDS, 'children'>, 'children'>>[]
    :
        | ChildOrder<PropagateExtraField<Exclude<EXTRA_FIELDS, 'children'>, 'children'>>[]
        | undefined /** not documented, bob specific */
} & ({ subscription: Subscription; type: OrderType.MONTHLY } | { subscription?: undefined; type?: OrderType.SINGLE }) &
  WithParam<true, WITH_PROJECT, { project: Project }> &
  WithExtra<
    {
      latest_user_project_comment: { latest_user_project_comment: Comment | undefined }
      shipping_trackings: { shipping_trackings: OrderShippingTracking[] }
      discount: { discount: { code: string } | undefined }
    },
    EXTRA_FIELDS
  >

type UserSocials = {
  provider: 'discord'
  user_id: string
  username: string
}
// TODO: merge with ShippingTracking
export type OrderShippingTracking = {
  created_at: string
  items?: {
    order_item: Omit<OrderItem, 'reward'> & {
      reward: Variant
    }
    quantity: number
  }[]
  status: ShippingTrackingStatus
  tracking_company?: string
  tracking_number?: string
  tracking_url?: string
}

export type ChildOrder<EXTRA_FIELDS extends string = ''> = Omit<Order<false, EXTRA_FIELDS>, 'project'> & {
  project: Project
  parent?: Order
  has_expired_return_deadline?: boolean /** not documented, bob specific */
} & WithExtra<{ returns: { returns: Omit<OrderReturn, 'order'>[] } }, EXTRA_FIELDS>

export type OrderItem<EXTRA_FIELDS extends string = ''> = {
  discount_total: number
  fulfillment_status?: ItemFulfillmentStatus /** not documented, bob specific */
  line_shipping_total: number
  line_subtotal: number
  line_total: number
  quantity: number
  reward: Reward<PropagateExtraField<EXTRA_FIELDS, 'reward'>> | Variant<PropagateExtraField<EXTRA_FIELDS, 'reward'>>
  reward_id: number
  unit_price: number
} & WithExtra<{ discount: { discount: { code: string } | undefined } }, EXTRA_FIELDS>

export enum ItemFulfillmentStatus {
  PARTIAL = 'partial',
  FULFILLED = 'fulfilled',
}

export type OrderList<WITH_PROJECT = false, EXTRA_FIELDS extends string = ''> = {
  orders: Order<WITH_PROJECT, EXTRA_FIELDS>[]
}

export enum OrderShippingType {
  NO_SHIPPING = 'no_shipping',
  USER_ADDRESS = 'user_address',
  PICKUP_POINT = 'pickup_point',
  MONDIAL_RELAY = 'dropoff',
}

export type UpdateOrder = {
  billing_address_id?: number
  hidden?: boolean
  note?: string
  private_note?: string
  shipping_address?: UpdateAddress & {
    delivery_type?: 'mondial_relay' | 'home_delivery'
    dropoff_id?: string
    dropoff_name?: string
  }
  billing_address?: UpdateAddress
  billing_and_shipping_address?: UpdateAddress
  shipping_address_id?: number
  user_id?: number
}

/**
 * ORDER RETURN
 */

export enum ReturnType {
  EXCHANGE = 'exchange',
  REFUND = 'refund',
  NEW_SHIPMENT = 'new_shipment',
}

export enum ReturnReasonType {
  CANCEL = 'cancel',
  WRONG_ITEM = 'wrong_item',
  DEFECTIVE_OR_DAMAGED_ITEM = 'defective_or_damaged_item',
  INCOMPLETE_ITEM = 'incomplete_item',
  MISSING_ITEM = 'missing_item',
  WRONG_DESCRIPTION = 'wrong_description',
  PURCHASE_MADE_BY_MISTAKE = 'purchase_made_by_mistake',
  SIZE_OR_COLOR_PROBLEM = 'size_or_color_problem',
  DOES_NOT_MEET_EXPECTATIONS = 'does_not_meet_expectations',
  CHANGE_OF_MIND = 'change_of_mind',
}

type CreateReturnItem = {
  reward_id: number
  type: ReturnType
  reason_type: ReturnReasonType
  quantity: number
  explanation?: string
  image_ids: number[]
}

export type CreateReturn = {
  dry_run: boolean
  items: CreateReturnItem[]
}

export enum ReturnShippingFeePolicy {
  OWNER = 'owner',
  CUSTOMER = 'customer',
}

export enum ReturnStatus {
  WAITING_APPROVAL = 'waiting-approval',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
}

export enum ReturnRefusalType {
  EXPIRED = 'expired',
  VENDOR = 'refused_by_vendor',
}

export enum ShippingTrackingStatus {
  WAITING_SHIPPING = 'waiting-shipping',
  IN_TRANSIT = 'in-transit',
  DELIVERED = 'delivered',
  INFO_RECEIVED = 'info-received',
  FAILED_ATTEMPT = 'failed-attempt',
  RETURNED = 'returned',
  EXPIRED = 'expired',
}

export type ShippingTracking = {
  created_at: string
  label?: ImageResource
  status: ShippingTrackingStatus
  tracking_url?: string
}

export type OrderReturn = {
  accepted_at?: string
  answer?: string
  created_at: string
  closed_at?: string
  is_closed: boolean
  is_refunded?: boolean
  id: number
  items: OrderReturnItem[]
  order: ChildOrder<'items.discount'>
  refund_discount_total: number
  refund_shipping_total: number
  refund_subtotal: number
  refund_total: number
  refunded_at?: string
  refusal_type?: ReturnRefusalType
  refused_at?: string
  shipping_fees_policy: ReturnShippingFeePolicy
  shipping_total?: number
  shipping_tracking?: ShippingTracking
  status: ReturnStatus
}

export type OrderReturnItem = {
  images?: ImageResource[]
  explanation?: string
  line_subtotal: number
  quantity: number
  reason_type: ReturnReasonType
  reward: Reward
  type: ReturnType
  unit_price: number
}

/**
 * MEMBER
 */
export type Member = {
  date_creation: string
  id: number
  role: string
  user: PublicUser
}

export type UpdateMember = {
  role: string
}

export type CreateMember = {
  role: string
  username: string
}

export type MemberList = {
  members: Member[]
}

/**
 * PARTNERSHIP
 */

export type Partner = {
  id: number
  logo: ImageResource
  name: string
  ribbon?: ImageResource
  slug: string
  url: string
  user_id: number

  is_default?: boolean /** not documented */
  ribbon_winner?: ImageResource /** not documented */
}

export type Partnership = {
  id: number
  is_default: boolean
  is_support: boolean
  ribbon?: ImageResource
  partner: Partner
  project: Project

  is_winner?: boolean /** not documented */
}

/**
 * PAYMENT
 */

export enum PaymentMethod {
  BANKWIRE = 'bankwire',
  CARD = 'card',
  CHECK = 'check',
  CREDITCARD = 'creditcard',
  DIRECTDEBIT = 'directdebit',
  IDEAL = 'ideal',
  MAESTRO = 'maestro',
  PAYLIB = 'paylib',
  PAYPAL = 'paypal',
  SAVING = 'saving',
}

export enum PaymentStatus {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  READY = 'ready',
  ERROR = 'error',
  PAYMENT_PROCESSING = 'payment-processing',
  COMPLETED = 'completed',
}

/**
 * PROJECT
 */

export enum FIELDSETS_KEYS {
  AVATAR_IMAGE = 'avatar_image',
  ABOUT = 'content.description',
  ACCOUNT = 'account',
  ANALYTICSTAGS = 'analyticstags',
  BACKGROUND_IMAGE_COLOR = 'background_image.bgcolor',
  CITY = 'city',
  COUNTRY = 'country',
  DATE_END = 'date_end',
  DELIVERY = 'delivery',
  DESCRIPTION_FUNDING = 'content.description_funding',
  DESCRIPTION_YOURSELF = 'content.description_yourself',
  FAQS = 'faqs',
  GOAL = 'goal',
  IMPACTS = 'impacts',
  MAIN_CATEGORY = 'main_tag',
  MAIN_IMAGE = 'main_image',
  NB_DAYS = 'nb_days',
  OWNER_AVATAR = 'owner.avatar',
  OWNER_SCREENNAME = 'owner.screenname',
  REWARDS = 'rewards',
  SUBTITLE = 'content.subtitle',
  TEAM = 'team',
  TITLE = 'content.name',
}

export type TrainingType = 'crowdfunding' | 'webmarketing-digital' | 'create-my-company' | 'professional-coaching'

type ProjectOwner<EXTRA_FIELDS extends string = ''> = PublicUser & { stats: UserStats } & WithExtra<
    {
      socials: { socials?: { provider: 'discord'; user_id: string; username: string }[] }
    },
    EXTRA_FIELDS
  >

export type ProjectExamples = [ProjectBase, ProjectBase, ProjectBase]

type ProjectBase<EXTRA_FIELDS extends string = ''> = {
  absolute_url: string
  amount_raised: number
  analytics_count: number
  automatic_goal: boolean
  avatar_image: I18n<Avatar> | undefined
  background_color: I18n<string> | undefined
  background_image: I18n<{ id: string; url: string }> | undefined
  boutique_link: Link | undefined
  city: string
  comments_allowed?: boolean
  comments_count: number
  comments_enabled: ProjectCommentsPermission
  committed: number
  country: string
  currency: string
  date_creation: string
  date_delivery?: string
  date_end_extra_time: string | undefined
  date_end: string | undefined
  date_online: string | undefined
  /* @deprecated Use `date_online` instead */
  date_start: string | undefined
  description: I18n
  description_funding: I18n
  description_yourself: I18n
  discussions_thread_id: number | undefined
  donations_monthly_allowed: boolean | undefined
  donations_single_allowed: boolean | undefined
  extra_time_canceled_at: string | undefined
  fans_count: number
  finished: boolean
  goal: number
  goal_description: I18n
  goal_title: I18n
  goal_raised: boolean
  goal_status: GoalStatus
  goal_type?: GoalType
  has_extra_time: boolean
  id: number
  is_cancelled: boolean
  is_in_extra_time: boolean
  is_online: boolean
  lang: Lang
  location: ProjectLocation | undefined
  lowest_contribution_amount: number
  main_image: I18n<ImageResource> | undefined
  main_tag: TagBase | undefined
  maximum_return_days?: number /** not documented, bob specific */
  membership_stats: MembershipStats
  moderation_message: DiscussionMessage & {
    read_by_owner: boolean
  }
  name: I18n
  nb_days: number | undefined
  nb_products_sold: number
  news_count: number
  nsfw?: boolean
  orders_count: number
  orders_enabled?: boolean
  owner: ProjectOwner<EXTRA_FIELDS>
  payment_methods: Array<string> | undefined
  permissions: ProjectPermissions
  phone_number_required?: boolean
  resource_uri: string
  share_image: I18n<ImageResource> | undefined
  shipping_address_required: boolean
  shipping_band_enabled: boolean
  shopify_vendor?: string /** not documented, boutique specific */
  show_created_projects: boolean
  show_goal?: boolean
  show_subscriptions_amount: boolean
  show_subscriptions_count: boolean
  slug: string
  sponsorships_count: number
  status: ProjectStatus
  subscriptions_amount?: number
  subscriptions_count?: number
  subtitle: I18n
  supporters_count: number
  tax_address_required?: boolean
  tax_deductible: boolean
  tax_deductible_rate?: TaxDeductibleRate
  tax_receipt_enabled: boolean
  thanks_note?: I18n
  timezone: string
  training_types?: TrainingType[]
  type: ProjectType
  urls: any
  video: I18n<VideoResource> | undefined
  visible: boolean
  validation_message: DiscussionMessage & {
    read_by_owner: boolean
  }

  required_personal_id_number?: boolean /** not documented */
  sharing_urls: I18n | undefined /** not documented */

  is_featured?: boolean /** WARN: this is an exception that only happens when using search API */
}

export type Project<EXTRA_FIELDS extends string = ''> = ProjectBase<EXTRA_FIELDS> &
  WithExtra<
    {
      account: { account: Account }
      analytics: { analytics: AnalyticsTag[] }
      answer_code: { answer_code: ProjectAnswerCode }
      billing: { billing: ProjectBilling | undefined }
      community_members_count: { community_members_count: number }
      default_manager: { default_manager: PublicUser | undefined }
      /** @deprecated use shippings instead */
      delivery: { delivery: Delivery | undefined }
      examples: ProjectExamplesResponse
      fields_needed: { fields_needed: string[] }
      fieldsets: { fieldsets: ProjectFieldset[] }
      latest_news: { latest_news: News | undefined }
      latest_project_online: {
        latest_project_online: Project | undefined
        show_latest_project_online: boolean
      }
      latest_supporter: { latest_supporter: PublicUser | undefined }
      links: { links: Link[]; post_campaign_link: Link }
      manager: { discussion_disabled: boolean; manager: Manager | undefined }
      onboarding_steps: { onboarding_steps: any }
      partnerships: { partnerships: Partnership[] }
      proposal: { proposal: Proposal }
      rewards: { rewards: Reward[] | undefined }
      share_images: {
        share_images: { facebook: I18n<string> | undefined; twitter: I18n<string> | undefined }
      } /** image processed from share_image for sharing **/
      slugs: { slugs: { id: number; slug: string }[] }
      socials: {
        discord_guild_id?: string
        discord_guild_name?: string
        discord_roles?: {
          id: number
          value: string
        }[]
      }
      sponsorships: { sponsorships: Sponsorship[] | undefined }
      shippings: { shippings: ShippingZones }
      tags: { tags: TagBase[] }
      tax_receipts_available: { tax_receipts_available: boolean }
      thread: { thread: DiscussionThread }
      unread_count: { unread_count: number }
      user_orders: { user_orders: Order[] }
      user_role: { user_role: UserRole }
      user_subscriptions: { user_subscriptions: Subscription[] }
      sensitive_terms: { sensitive_terms: SensitiveTerm[] | undefined }
      membership_stats: { membership_stats: MembershipStats }
    },
    EXTRA_FIELDS
  >

export type MembershipStats = {
  balance: Balance
  free_subscriptions_count: number
  paid_subscriptions_count: number
}

export enum ProjectAnswerCode {
  ACCEPTED = 'accepted',
  NEED_MODERATION = 'need-moderation',
}

export type ProjectBilling = {
  invoice: Invoice | undefined
  payment_status: PaymentStatus
}

export type ProjectDropoffPointsRequest = {
  address: string
  carrier: 'mondial_relay'
  city: string
  country: string
  latitude_address: number
  latitude_center_map?: number
  longitude_address: number
  longitude_center_map?: number
  provider?: 'mondial_relay' | 'boxtal' | 'shippypro'
  range?: number
  zip: string
}

export type DropoffPoint = {
  carrier: 'mondial_relay'
  city: string
  country: string
  day_of_week: {
    day: string
    hours: {
      close: string
      open: string
    }[]
  }[]
  distance: number
  latitude: number
  longitude: number
  point_id: string
  point_name: string
  street: string
  zip: string
}

export type ProjectDropoffPointsResponse = {
  points: DropoffPoint[]
}

export type ProjectExamplesResponse = {
  examples: ProjectExamples
}

export type ProjectExamplesRequest = {
  force_recompute?: boolean
  with_delay?: boolean
}

export type ProjectFieldset = { completed: boolean; key: FIELDSETS_KEYS; required: boolean }

export type ProjectLocation = {
  city: string
  country: string
}

export enum ProjectOffboardingType {
  BUY = 'buy',
  FOLLOW = 'follow',
  SUPPORT = 'support',
}

export type ProjectList<EXTRA_FIELDS extends string = ''> = {
  projects: Project<EXTRA_FIELDS>[]
}

export enum ProjectStatus {
  ONLINE = 'online',
  NEW = 'new',
  PENDING = 'pending',
  PENDING_OWNER = 'pending-owner',
  VALIDATED = 'validated',
  REFUSED = 'refused',
  WAITING = 'waiting',
  PENDING_FINAL_VALIDATION = 'pending-final-validation',
}

export enum GoalStatus {
  FUNDING = 'funding',
  SUCCESS = 'success',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  FRAUD = 'fraud',
}

export enum GoalType {
  QUANTITATIVE = 'quantitative',
  FINANCIAL = 'financial',
}

export type ProjectSubmitRequest = {
  message?: string
}

export type ProjectPublishRequest = {
  date_end?: string
  timezone: string
}

export enum ProjectType {
  Donation = 'donation',
  Presale = 'presale',
  Project = 'project',
  Vendor = 'vendor',
  Membership = 'membership',
}

export type SensitiveTerm = {
  match: string[]
  query_id: number
  query_metadata: {
    theme: string
  }
}

export type UpdateProject<EXTRA_FIELDS extends string = ''> = {
  account_id?: number | null
  account?: Account
  avatar_image_id?: I18n<number>
  background_color?: I18n<string | null>
  background_image_id?: I18n<number | null>
  city?: string
  country?: string
  currency?: string
  date_delivery?: string
  date_end?: string | null
  description_funding?: I18n<string | null>
  description_yourself?: I18n<string | null>
  description?: I18n<string | null>
  donations_monthly_allowed?: boolean
  donations_single_allowed?: boolean
  goal?: number | null
  goal_description?: I18n<string | null>
  goal_title?: I18n<string | null>
  hide_goal?: boolean
  lang?: string
  main_image_id?: I18n<number>
  main_tag?: string
  name?: I18n<string | null>
  nsfw?: boolean
  nb_days?: number | null
  phone_number_required?: boolean
  post_campaign_link?: {
    title?: I18n<string | null>
    url?: string
  } | null
  share_image_id?: I18n<number> /** for membership */
  shipping_address_required?: boolean /** for membership */
  show_created_projects?: boolean /** for membership */
  show_latest_project_online?: boolean /** for membership */
  show_subscriptions_amount?: boolean /** for membership */
  show_subscriptions_count?: boolean /** for membership */
  shipping_type?: ShippingKind
  slug?: string
  slugify?: boolean
  subtitle?: I18n<string | null>
  tags?: string[]
  tax_address_required?: boolean
  tax_deductible?: {
    association_type?: AssociationType
    create_rewards?: number[]
    delete_rewards?: number[]
    enabled?: boolean
    entity_name?: string
    legal_representative_image_signature?: number
    legal_representative_job_title?: string
    rate?: TaxDeductibleRate
    receipt_enabled?: boolean
  }
  thanks_note?: I18n<string | null>
  timezone?: string
  training_types?: TrainingType[]
  type?: ProjectType
  video_url?: I18n<string | null>

  update_rewards?: {
    id: number
    position?: number
    social_ids?: number[]
  }[]

  // Project links
  create_links?: {
    title?: I18n
    url: string
  }[]
  delete_links?: number[]
  update_links?: {
    id: number
    title?: I18n
    url: string
  }[]
} & WithExtra<
  {
    onboarding_steps: { onboarding_steps?: any }
    slugs: { slugs?: string[] }
  },
  EXTRA_FIELDS
>

export type UserProjectSort = 'created_at' | 'followed_at' | 'supported_at'

export type UserProjectState = 'created' | 'followed' | 'online' | 'supported'

// Project permissions

export type ProjectPermissions = {
  self: [ProjectSelfPermission] | [ProjectSelfPermission, ProjectSelfPermission] | null
  news: [ProjectNewsPermission] | [ProjectNewsPermission, ProjectNewsPermission] | null
}

export enum ProjectCommentsPermission {
  EVERYONE = 'everyone',
  SUPPORTERS = 'supporters',
  NOONE = 'disabled',
}

export enum ProjectNewsPermission {
  CREATE = 'create',
  READ = 'read',
}

export enum ProjectSelfPermission {
  UPDATE = 'update',
  READ = 'read',
}

/**
 * PROJECT EVENT
 */

export type Event =
  | EventFirstOrder
  | EventNews
  | EventPercentRaised
  | EventProjectEnd
  | EventProjectEndExtraTime
  | EventProjectStart
  | EventSponsorship

export type EventFirstOrder = {
  data: Order
  time: string
  type: EventType.FIRST_ORDER
}

export enum EventGoalStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type EventList = {
  draft_news?: News<false, true>[]
  events: Event[]
}

export type EventNews = {
  data: News<false, true>
  time: string
  type: EventType.NEWS
}

export type EventPercentRaised = {
  data: {
    percent: number
  }
  time: string
  type: EventType.PERCENT_RAISED
}

export type EventProjectEnd = {
  data: {
    goal_status: EventGoalStatus
    has_extra_time: boolean
  }
  time: string
  type: EventType.PROJECT_END
}

export type EventProjectEndExtraTime = {
  time: string
  type: EventType.PROJECT_END_EXTRATIME
}

export type EventProjectStart = {
  time: string
  type: EventType.PROJECT_START
}

export type EventSponsorship = {
  data: Sponsorship
  time: string
  type: EventType.SPONSORSHIP
}

export enum EventType {
  NEWS = 'news',
  PERCENT_RAISED = 'percent_raised',
  FIRST_ORDER = 'first_order',
  PROJECT_START = 'project_start',
  PROJECT_END_EXTRATIME = 'project_end_extra_time',
  PROJECT_END = 'project_end',
  SPONSORSHIP = 'sponsorship',
}

/**
 * PROPOSAL
 */

export enum ProposalTypeNumber {
  PRESALE = 1,
  PROJECT = 2,
  MEMBERSHIP = 5,
}

export enum ProposalType {
  PRESALE = 'presale',
  PROJECT = 'project',
}

export enum LegalEntity {
  PERSONAL = 'personal',
  BUSINESS = 'business',
  ASSOCIATION = 'association',
}

export enum RewardType {
  CONCRETE = 'concrete',
  SYMBOLIC = 'symbolic',
  NONE = 'none',
  UNDEFINED = 'undefined',
}

export type CreateProposal = {
  address?: string
  birthday?: string
  city?: string
  community_range?: { min?: number; max?: number }
  country: string
  currency?: string
  description?: string
  first_name?: string
  goal?: number
  goal_range?: { min?: number; max?: number }
  lang?: string
  last_name?: string
  legal_entity_type?: LegalEntity
  links?: string[]
  link_ids?: number[]
  name?: string
  nationality?: string
  partner_id?: number
  /** not documented */
  phone_number?: string
  rewards?: string
  rewards_type?: RewardType
  structure?: string
  type?: ProposalTypeNumber
  nb_products_min?: number
  /** doc says it's required depending on type, but it works without `nb_products_min` for each type */
  auto_validation: true
  /** not documented, boolean used during the transition from an old version of proposal where validation was triggered manually. This old version does not exists anymore so the value is fixed */
  switch_okpal_to_donation: boolean /** not documented, boolean used during the transition from reward based projects only to donation or reward based projects */
}

export type RefuseProposal = (
  | {
      answer: string
    }
  | {
      answer_html: string
    }
) & {
  answer_code: ProposalAnswerCode
}

export type CreateAnonymousProposal = CreateProposal & {
  email: string
}

export enum DatStartEstimation {
  AS_SOON_AS_POSSIBLE = 'as soon as possible',
  IN_THE_MONTH = 'in the month',
  IN_ONE_OR_THREE_MONTHS = 'in one to three months',
  IN_MORE_THAN_THREE_MONTHS = 'in more than three months',
}

export enum ProposalStatus {
  PENDING = 'pending',
  NEW = 'new',
  VALID = 'valid',
  INVALID = 'invalid',
  WAITING = 'waiting',
  UPDATED = 'updated',
}

export enum ProposalAnswerCode {
  PENDING = 'pending',
  PENDING_PARTNER = 'pending-partner',
  PENDING_TIMEOUT = 'pending-timeout',
  VALID = 'valid',
  VALID_PARTNER = 'valid-partner',
  VALID_TIMEOUT = 'valid-timeout',
  INVALID_OKPAL = 'invalid-okpal',
  INVALID_COUNTRY = 'invalid-country',
  INVALID_DUPLICATE = 'invalid-duplicate',
}

export type Proposal<EXTRA_FIELDS extends string = '', IS_STAFF extends boolean = false> = {
  address: string
  answer: string
  answer_code?: ProposalAnswerCode
  answer_html: string
  category: Category | null
  category_id: number | null
  city: string
  country: string
  currency: string
  date_creation: string
  date_start_estimation: DatStartEstimation | null
  date_update: string
  description: string
  email: string
  first_name: string
  goal: number
  goal_range: { min?: number; max?: number }
  id: number
  is_reachable: boolean /** not documented */
  lang: string
  last_name: string
  legal_entity_type: LegalEntity
  name: string
  nb_products_min: number
  partner: Partner
  phone_number: string
  project: Project | null
  references: string
  resource_uri: string
  rewards: string
  rewards_type: RewardType
  status: ProposalStatus
  structure: string
  token: string
  type: ProposalType
  user: PublicUser
} & WithParam<
  true,
  IS_STAFF,
  {
    manager: PublicUser | null
    score: number
    score_entries: ScoreEntry
  }
> &
  WithExtra<
    {
      links: { links: Link[] }
    },
    EXTRA_FIELDS
  >

export type UpdateProposal = Partial<Proposal> & {
  link_ids?: number[]
}

export type ScoreEntry = {
  description: number
  goal_range: number
  legal_entity_type: number
  links: number
  rewards_type: number
  user: number
}

/**
 * REFERRAL
 */

export type Referral = {
  amount_raised: number
  contributions_count: number
  is_backer: boolean
  rank: number
  user: AuthenticatedUser
}

export type ReferralList = {
  referrals: Referral[] | null
}

export type ReferralListPage = Pageable<ReferralList>

/**
 * REWARD
 */
export type CreateReward = {
  description: I18n<string | null>
  dry_run?: boolean
  image_id: I18n<number | null>
  title: I18n<string | null>
} & (
  | {
      is_free: true
      price?: undefined
      variants?: undefined
      stock?: undefined
      is_featured?: undefined
      is_hidden?: undefined
      num_products?: undefined
      phone_number_required?: undefined
      shipping_address_required?: undefined
      tax_deductible?: undefined
    }
  | {
      is_free?: false
      price: number
      variants?: { description: I18n; position: number; stock?: number | null }[]
      stock?: number | null
      is_featured?: boolean
      is_hidden?: boolean
      num_products?: number
      phone_number_required?: boolean
      shipping_address_required?: boolean
      tax_deductible?: boolean
    }
)
/** for membership */

export type UpdateReward = Partial<Omit<CreateReward, 'variants'>> & {
  create_variants?: {
    description: I18n
    image_id?: I18n<number | null>
    position: number
    stock?: number | null
  }[]
  delete_variants?: number[]
  update_variants?: {
    description: I18n
    id: number
    image_id?: I18n<number | null>
    position: number
    stock: number | null
  }[]
  delete_custom_delivery?: boolean
}

export type Reward<EXTRA_FIELDS extends string = ''> = {
  available: boolean
  delivery?: Delivery
  description: I18n
  has_custom_delivery: boolean
  id: number
  image?: I18n<ImageResource>
  is_featured: boolean
  is_free?: boolean /** only for memberships */
  is_hidden: boolean
  is_returnable?: boolean /** not documented, bob specific */
  is_virtual?: boolean /** not documented, bob specific */
  num_products?: number
  phone_number_required?: boolean
  position?: number
  price: number
  project_id: number
  project?: Project
  resource_uri: string
  shippings?: {
    date_delivery: string
    phone_number_required: boolean
    shipping_type: ShippingKind
    zones: RewardDeliveryZone[]
  }
  shipping_address_required?: boolean
  shopify_handle?: string /** not documented, bob specific */
  shopify_product_id?: number /** not documented, bob specific */
  socials?: {
    id: number
    value: string
  }[]
  stock: number | null
  stock_available: number | null
  stock_taken: number
  tags?: string[] /** not documented, bob specific */
  tax_deductible_price: number
  tax_deductible: boolean
  thanks_note: I18n<string | null>
  title: I18n
  variants?: Variant<EXTRA_FIELDS>[]
}

export type RewardPayload = {
  quantity: number
  reward_id: number
}

export type Variant<EXTRA_FIELDS extends string = ''> = {
  available: boolean
  description: I18n
  id: number
  image?: I18n<ImageResource>
  is_virtual?: boolean /** not documented, bob specific */
  parent?: Reward<EXTRA_FIELDS>
  price?: number /** not documented, bob specific */
  stock: number | null
  stock_available: number | null
  stock_taken: number
  title?: I18n /** not documented, bob specific */
} & WithExtra<
  {
    user_review: { user_review: Review | undefined }
  },
  EXTRA_FIELDS
>

/**
 * SDG
 */

export type Impact = {
  certifications: I18n
  challenges: I18n
  impact: I18n
  sdgs?: ProjectSDG[]
}

export type ProjectSDG = {
  description: I18n
  id: number
  sdg: SDG
}

export type SDG<WITH_TARGET = false> = {
  code: string
  description: I18n
  logo: I18n<ImageResource>
  title: I18n
} & WithParam<true, WITH_TARGET, { targets: SDG[] }>

export type SDGList = {
  sdgs: SDG<true>[]
}

export type UpdateImpact = Omit<Impact, 'sdgs'> & {
  create_sdgs: {
    description: I18n
    sdg_code: string
  }[]
  update_sdgs: {
    description: I18n
    id: number
  }[]
  delete_sdgs: number[]
}

/**
 * SEARCH
 */

export type SearchQualifier = {
  channel_ids?: number[]
  city_id?: number
  country?: string
  lang?: string
  owner_id?: number
  partners?: string[]
  quality_score?: QualityScore[]
  quality_score__not?: QualityScore[]
  region_id?: number
  sort?: 'popular' | 'amount' | 'ending-soon' | 'position' | 'new' | 'staff-pick'
  status?: 'currently' | 'all' | 'success' | 'ended'
  tag_id?: number
}

export type QualityScore = 'A' | 'B' | 'C' | 'X'

/**
 * SHIPPING
 */

export type UpdateDelivery = Partial<Delivery> & {
  create_shippings?: {
    amount: number
    zone?: string
    countries?: string[] | null
  }[]
  delete_shippings?: number[]
  update_shippings?: {
    id: number
    amount: number
    zone?: string
    countries?: string[] | null
  }[]
  create_pickup_points?: { name: I18n; address: CreateAddress | null; description: I18n; position: number }[]
  delete_pickup_points?: number[]
  update_pickup_points?: {
    id: number
    name: I18n
    address: CreateAddress | null
    description: I18n
    position: number
  }[]
}

/** @deprecated use shippings instead */
export type Delivery = {
  address_required: boolean
  date_delivery: string | undefined
  /** @deprecated use address_required_before_checkout instead */
  force_address_required?: boolean
  address_required_before_checkout?: boolean
  force_phone_number_required?: boolean
  has_shippings: boolean
  phone_number_required: boolean
  pickup_points?: PickupPoint[]
  shipping_int: number | null
  shipping_nat: number | null
  shipping_type: ShippingType | undefined
  shippings?: Shipping[]
}

export type PickupPoint = {
  address?: Address
  description: I18n
  name: I18n
  /** @deprecated */
  raw_address: I18n
  id: number
}

export type Shipping = {
  amount: number
  countries: Array<string> | null
  id: number
  zone?: string | null
}

export type ShippingKind = 'physical-delivery' | 'none'

export type DeliveryType = 'mondial_relay' | 'home_delivery'

export type BandType = 'priced_per_cart' | 'fixed_price'

export type ShippingZones = {
  date_delivery: string
  shipping_type: ShippingKind
  phone_number_required: boolean
  tax_address_required: boolean
  zones: DeliveryZone[]
}

export type DeliveryZone = {
  areas: string[] | null
  countries: string[] | null
  all_countries: string[] | null
  is_default: boolean
  id: number
  pickup_points: PickupPoint[] | null
  home_delivery?: DeliveryMode
  mondial_relay_delivery?: DeliveryMode
}

type DeliveryMode = {
  bands: ShippingBand[]
  id: number
  band_type: BandType
}

export type UpdateShippingZone = Partial<{
  countries: string[]
  areas: string[]
  create_deliveries: CreateDeliveryZone[]
  update_deliveries: UpdateDeliveryZone[]
  delete_deliveries: number[]
  create_pickup_points: CreatePickupPoint[]
  update_pickup_points: (Partial<CreatePickupPoint> & { id: number })[]
  delete_pickup_points: number[]
}>

export type CreateShippingZone = {
  is_default: boolean
  countries: string[]
  areas: string[]
  create_deliveries: CreateDeliveryZone[]
  create_pickup_points: CreatePickupPoint[]
}

export type UpdateDeliveryZone = Partial<{
  type: DeliveryType
  band_type: BandType
  create_bands: CreateShippingBand[]
  update_bands: UpdateShippingBand[]
  delete_bands: number[]
}>

export type CreateDeliveryZone = {
  type: DeliveryType
  band_type: BandType
  create_bands: CreateShippingBand[]
}

type CreatePickupPoint = { name: I18n; address: CreateAddress | null; description: I18n; position: number }

export type ShippingBand = {
  id: number
  price: number
  threshold?: number
  price_increase?: number
}

export type CreateShippingBand = {
  price: number
  threshold?: number
  price_increase?: number
}

export type UpdateShippingBand = Partial<CreateShippingBand> & { id: number }

export enum ShippingStatus {
  WAITING_SHIPPING = 'waiting-shipping',
  IN_TRANSIT = 'in-transit',
  DELIVERED = 'delivered',
  INFO_RECIEVED = 'info-received',
  FAILED_ATTEMPT = 'failed-attempt',
  RETURNED = 'returned',
  EXPIRED = 'expired',
}

/** @deprecated use shipping and ShippingKind */
export enum ShippingType {
  NONE = 'none',
  NATIONAL_ONLY = 'national-only',
  NATIONAL_AND_SOME_COUNTRIES = 'national-and-some-countries',
  WORLDWIDE = 'worldwide',
}

export type RewardDeliveryZone = DeliveryZone & {
  additional_cost: number | null | undefined
  is_active: boolean
}

export type CreateRewardShipping = {
  date_delivery: string
  phone_number_required: boolean
  shipping_type: ShippingKind
  zones?: {
    id: number
    additional_cost: number | null
    is_active: boolean
  }[]
}

export type RewardShipping = {
  date_delivery?: string
  phone_number_required?: boolean
  shipping_type?: ShippingKind
  zones?: RewardDeliveryZone[]
}

/**
 * SPONSORSHIP
 */

export type Sponsor = {
  absolute_url: string
  id: number
  image: ImageResource
  link: string
  name: string
  position: number
  type: string
  user: PublicUser
}

export type Sponsorship<EXTRA_FIELDS extends string = ''> = {
  amount: number
  coefficient: number
  description: I18n
  id: number
  is_full: boolean
  main_color: string
  mode: SponsorshipMode
  priority: number
  project_id: number
  sponsor: Sponsor
  type?: SponsorshipType
} & WithExtra<{ channel: { channel: Channel | undefined } }, EXTRA_FIELDS>

export enum SponsorshipMode {
  PARTICIPATES_IN = 'participates_in',
  SUPPORTED_BY = 'supported_by',
  PRIZE_WINNER = 'prize_winner',
  CRUSH = 'crush',
  COACHED_BY = 'coached_by',
  BENEFIT_OF = 'benefit_of',
}

export enum SponsorshipType {
  AMOUNT = 'amount',
  PRIZE_WINNER = 'prize_winner',
  DOUBLED_DONATIONS = 'doubled_donations',
  CRUSH = 'crush',
  AUDIENCE = 'audience',
}

/**
 * SUBSCRIPTION
 */

export type CreateSubscription = {
  reward_id?: number
  shipping_address_id?: number
  billing_address_id?: number
  payment_method: PaymentMethod
  return_url: string
  total?: number
}

export type Subscription = {
  billing_address?: Address
  card_date_expiration?: string
  card_holder_name?: string
  card_number?: string
  created_at: string
  edit_url?: string
  id: number
  orders_count?: number
  payment_method?: PaymentMethod
  payment_url?: string
  project?: Project
  is_cancellable?: boolean
  latest_order?: Order
  next_payment_expected_at?: string
  cancelable_at?: string
  reward?: Reward
  status: SubscriptionStatus
  total: number
  user?: Supporter & { email: string }
}

export enum SubscriptionStatus {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

/**
 * TAG
 */

export type Tag = TagBase & {
  accent_color: string
  bg_color: string
  category: Category
  description: I18n
  subtitle: I18n | null
  is_visible: boolean
}

export type TagBase = {
  absolute_url: string
  id: number
  name: I18n
  position: number
  slug: string
}

export type TagList = {
  tags: Tag[]
}

/**
 * USER
 */

export type AuthenticatedUser<EXTRA_FIELDS extends string = ''> = PublicUser & {
  birthday: string
  email: string
  nationality?: string
  personal_id_number?: string
} & WithExtra<
    {
      contact_lang: { contact_lang: string | undefined }
      orders: { orders: Order<false, PropagateExtraField<EXTRA_FIELDS, 'orders'>>[] | undefined }
      projects: { projects: Project<PropagateExtraField<EXTRA_FIELDS, 'projects'>>[] | undefined }
      stats: { stats: UserStats | undefined }
    },
    EXTRA_FIELDS
  >

export type DeleteUser = {
  dry_run?: boolean
}

export type DeleteUserResponse = {
  can_delete: {
    level: 'blocked' | 'info' | 'warning'
    link: string
    message_code:
      | 'user_scratch_project'
      | 'user_live_project_must_end'
      | 'user_project_must_wait_withdrawal_transfer'
      | 'user_live_project_contact_team'
      | 'user_completed_project_less_18_months'
      | 'user_cancelable_order'
      | 'user_not_cancelable_order'
      | 'user_finished_projets'
  }[]
}

export type Fan<EXTRA_FIELDS extends string = ''> = PublicUser & {
  is_anonymous?: boolean
} & WithExtra<{ labels: { labels: UserLabel[] | undefined } }, EXTRA_FIELDS>

export type FanList<EXTRA_FIELDS extends string = ''> = {
  fans: Fan<EXTRA_FIELDS>[] | null
}

export type Manager = PublicUser

export type PasswordStrength = 'worst' | 'bad' | 'weak' | 'good' | 'strong'

export type PublicUser = {
  absolute_url: string
  avatar: Avatar
  country: string
  date_joined: string
  description: I18n
  first_name: string
  has_avatar: boolean
  has_usable_password: boolean
  id: number
  is_active: boolean
  /** @deprecated */
  is_completed: boolean
  is_guest: boolean
  is_staff: boolean
  is_public: boolean
  lang: string
  last_login: string
  last_name: string
  location: string
  name: string
  presentation: I18n
  resource_uri: string
  role: UserRole // TODO: Marked as depending on 'role' extra field. Investigate w/ the API team in which cases this is useful or needed.
  screenname: string
  timezone: string
  username: string
}

export type PublicUserList = {
  users: PublicUser[]
}

export type Supporter<EXTRA_FIELDS extends string = ''> = PublicUser & {
  is_anonymous: boolean
} & WithExtra<
    {
      labels: { labels: UserLabel[] | undefined }
      latest_project_comment: { latest_project_comment: Comment | undefined }
      latest_project_order: { latest_project_order: Order | undefined }
    },
    EXTRA_FIELDS
  >

export type SupporterList<EXTRA_FIELDS extends string = ''> = {
  supporters: Supporter<EXTRA_FIELDS>[]
}

export type UpdateUser = {
  birthday?: string
  contact_lang?: string
  country?: string
  description?: I18n<string | null>
  email?: string
  first_name?: string
  ip_address?: string
  lang?: Lang
  last_name?: string
  location?: string
  nationality?: string
  newsletters?: string[]
  personal_id_number?: string
  presentation?: I18n<string | null>
  screenname?: string
  username?: string
}

export type UserDiscussionsStats = {
  unread_count: number
}

export enum UserLabel {
  Captain = 'captain',
  Connector = 'connector',
  Expert = 'expert',
  Official = 'official',
  Team = 'team',
}

export enum UserRole {
  Editor = 'editor',
  Fan = 'fan',
  Moderator = 'moderator',
  Owner = 'owner',
  Supporter = 'supporter',
}

type UserStats = {
  created_projects_online_count: number
  followed_projects_count: number
  projects_created_count: number
  projects_currently_funding_count: number
  projects_failed_count: number
  projects_orders_count: number
  projects_succeeded_count: number
  proposals_created_count: number
  proposals_refused_count: number
  proposals_validated_count: number
  supported_projects_count: number
}

export type UserSocialProvider = 'discord'
export type UnlinkSocialConnect = {
  provider: UserSocialProvider
}

/**
 * WITHDRAWAL
 */

export enum WithdrawalStatus {
  SUCCEEDED = 'succeeded',
  WAITING = 'waiting',
  PENDING = 'pending',
  FAILED = 'failed',
}

export type Withdrawal = {
  amount: number
  created_at: string
  executed_at: string | null
  fee: number
  id: number
  invoice?: {
    gross_commission: number
    rebate: number
    reference: string
    total_commission: number
    transferred_amount: number
    total_amount: number
    url: string
    vat_amount: number
    vat_commission: number
    vat_rate: number
  }
  status: WithdrawalStatus
  updated_at: string | null
}

type Balance = {
  amount_raised: number
  available: {
    amount: number
    tip_balance: number
    order_balance: number
  }
  pending: {
    amount: number
  }
  processing: {
    amount: number
  }
  transferred: {
    amount: number
    fee: number
  }
}

export type WithdrawalPage<WITH_BALANCE extends boolean = false> = Pageable<
  {
    withdrawals: Withdrawal[]
  } & WithParam<true, WITH_BALANCE, { balance: Balance }>
>

/**
 * VIDEO
 */

/** @deprecated Set to be removed from the Ulule API, use the `video_url` field in the ProjectUpdate payload instead. */
export type CreateProjectVideo = {
  language: Lang
  url: string
}

export type VideoResource = {
  author_name: string
  author_url: string
  height: number
  html: string
  id: number
  language: string
  provider_name: string
  provider_url: string
  thumbnail_height: number
  thumbnail_url?: string
  thumbnail_width: number
  title: string
  type: string
  url: string
  version: string
  width: number
}

/**
 * REVIEW
 */

export type CreateReview = {
  name: string
  comment: string
  score: number
}

export type UpdateReview = Partial<CreateReview>

export type Review = {
  comment: string
  created_at: string
  id: number
  is_highlighted: boolean
  name: string
  order: Order
  project?: Project
  reward?: Variant
  score: number
}

export type ReviewList = {
  reviews: Review[]
}

export type ProductReviewPage = {
  avg_score?: number
  reviews: Review[]
}

export type ShippingRateRequest = {
  country: string
  items: { quantity: number; shopify_variant_id: number }[]
}

export type ShippingRateList = {
  rates: {
    free_shipping_threshold: number | null
    name: string
    price: number
    project: Project
  }[]
  supported_countries: string[]
}

export enum ImportType {
  MEMBER = 'member',
  PREVIOUS_BACKER = 'previous-backer',
  NONE = 'none',
}
