import { createClient, endpoint } from '../../client.ts'
import * as api from './types.ts'
import { setCookie, getCookie } from '@owl-nest/cookie-helper'
import { djangoOauth2 } from '../../oauthProxy.ts'
import * as UFE from '../../UFE.ts'

export const client = createClient({
  endpoints: [
    // ACCOUNTS
    endpoint('GET /accounts/:id').config<{
      output: api.Account
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('PATCH /accounts/:id').config<{
      input: api.UpdateAccount
      output: api.Account
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('DELETE /accounts/:id').config({
      withToken: true,
    }),

    // ADDRESSES
    endpoint('GET /addresses/:id').config<{
      output: api.Address
    }>({
      withToken: true,
    }),
    endpoint('PATCH /addresses/:id').config<{
      input: api.UpdateAddress
      output: api.Address
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('DELETE /addresses/:id').config({
      withToken: true,
    }),

    // ANALYTICS
    endpoint('DELETE /analytics/:id').config({
      withToken: true,
    }),

    // CATEGORIES
    endpoint('GET /categories').config<{
      output: api.Pageable<api.CategoryList>
    }>({}),

    // CHANNELS
    endpoint('GET /channels').config<{
      output: api.Pageable<api.ChannelList>
    }>({}),

    // COMMENTS
    endpoint('DELETE /comments/:id').config({
      withToken: true, // only accessible to staff
    }),
    endpoint('POST /comments/:id/replies').config<{
      input: api.CreateComment
      output: api.Comment
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /comments/:id/replies').config<{
      output: api.Pageable<api.CommentList<false>>
    }>({}),

    // DISCUSSIONS
    endpoint('GET /discussions/me').config<{
      output: api.UserDiscussionsStats
    }>({
      withToken: true,
    }),

    // IMAGES
    endpoint('DELETE /images/:id').config({
      withToken: true,
    }),

    // LINKS
    endpoint('POST /links').config<{
      input: api.CreateLink
      output: api.Link
    }>({
      type: 'json',
    }),

    // ME
    endpoint('GET /me').config<{
      output: api.AuthenticatedUser
    }>({
      withToken: true,
    }),

    // MEMBERS
    endpoint('DELETE /members/:id').config({
      withToken: true,
    }),
    endpoint('PATCH /members/:id').config<{
      input: api.UpdateMember
      output: api.Member
    }>({
      withToken: true,
      type: 'json',
    }),

    // NEWS
    endpoint('GET /news/:id').config<{
      output: api.News<true, true>
    }>({}),
    endpoint('GET /news/:id/comments').config<{
      output: api.Pageable<api.CommentList<true>>
    }>({}),
    endpoint('PATCH /news/:id').config<{
      input: api.CreateNews
      output: api.News<true, true>
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /news/:id/comments').config<{
      input: api.CreateComment
      output: api.Comment<true>
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /news/:id/publish').config<{
      output: api.News<true, true>
    }>({
      withToken: true,
    }),
    endpoint('POST /news/:id/unpublish').config<{
      output: api.News<true, true>
    }>({
      withToken: true,
    }),
    endpoint('POST /news/:id/try').config({
      withToken: true,
    }),
    endpoint('DELETE /news/:id').config({
      withToken: true,
    }),

    // NEWSLETTER
    endpoint('POST /newsletters/monthly').config<{
      input: { country: string; email: string; lang: string }
    }>({
      type: 'json',
    }),

    // OFFICIALUSER
    endpoint('GET /officialusers').config<{
      output: api.Pageable<api.PublicUserList>
    }>({}),

    // ORDERS
    endpoint('GET /orders/:id').config<{
      output: api.Order<true>
    }>({
      withToken: true,
    }),
    endpoint('PATCH /orders/:id').config<{
      input: api.UpdateOrder
      output: api.Order
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /orders/:id/cancel').config<{
      output: api.Order
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /orders/:id/mark-as-delivered').config<{
      output: api.Order<true>
    }>({
      withToken: true,
    }),
    endpoint('POST /orders/:id/mark-as-undelivered').config<{
      output: api.Order<true>
    }>({
      withToken: true,
    }),
    endpoint('POST /orders/:id/renew').config<{
      input: Partial<api.Order>
      output: api.Order
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /orders/:id/returns').config<{
      input: api.CreateReturn
      output: api.OrderReturn
    }>({
      withToken: true,
      type: 'json',
    }),

    // PARTNERS
    endpoint('GET /partners/:id').config<{
      output: api.Partner
    }>({}),

    // PASSWORD
    endpoint('POST /passwords/score').config<{
      input: { password: string }
      output: { score: api.PasswordStrength }
    }>({
      type: 'json',
    }),

    // PROJECT
    endpoint('GET /projects/:id').config<{
      output: api.Project
    }>({}),
    endpoint('PATCH /projects/:id').config<{
      input: api.UpdateProject
      output: api.Project
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/analytics').config<{
      output: api.AnalyticsTags
    }>({
      withToken: true,
    }),
    endpoint('POST /projects/:id/analytics').config<{
      input: api.CreateAnalyticsTag
      output: api.NewAnalyticsTag
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/cancel').config<{
      output: api.Project
    }>({
      withToken: true,
    }),
    endpoint('GET /projects/:id/comments').config<{
      output: api.Pageable<api.CommentList<true>>
    }>({}),
    endpoint('POST /projects/:id/comments').config<{
      input: api.CreateComment
      output: api.Comment<true>
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/compute-examples').config<{
      input: api.ProjectExamplesRequest
      output: api.ProjectExamplesResponse | undefined
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('PATCH /projects/:id/delivery').config<{
      input: api.UpdateDelivery
      output: api.Delivery
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/dropoff-points').config<{
      input: api.ProjectDropoffPointsRequest
      output: api.ProjectDropoffPointsResponse
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/end').config<{
      output: api.Project
    }>({
      withToken: true,
    }),
    endpoint('GET /projects/:id/events').config<{
      output: api.Pageable<api.EventList>
    }>({}),
    endpoint('POST /projects/:id/exports').config<{
      input: api.CreateExport
      output: api.Export
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/fans').config<{
      output: api.Pageable<api.FanList>
    }>({}),
    endpoint('POST /projects/:id/faq').config<{
      input: api.CreateFaq
      output: api.Faq
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/faq').config<{
      output: api.Pageable<api.FaqList>
    }>({}),
    endpoint('POST /projects/:id/images').config<{
      input: api.CreateImage
      output: api.ImageResource
    }>({
      withToken: true,
      type: 'formdata',
    }),
    endpoint('GET /projects/:id/images').config<{
      output: api.Pageable<{ images: api.ImageResource[] | null }>
    }>({
      withToken: true,
    }),
    endpoint('GET /projects/:id/impact').config<{
      output: api.Impact
    }>({}),
    endpoint('PATCH /projects/:id/impact').config<{
      input: api.UpdateImpact
      output: api.Impact
    }>({
      type: 'json',
      withToken: true,
    }),
    endpoint('POST /projects/:id/like').config({
      withToken: true,
    }),
    endpoint('GET /projects/:id/members').config<{
      output: api.MemberList
    }>({
      withToken: true,
    }),
    endpoint('POST /projects/:id/members').config<{
      input: api.CreateMember
      output: api.Member
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/news').config<{
      output: api.Pageable<api.NewsList<true>>
    }>({}),
    endpoint('POST /projects/:id/news').config<{
      input: api.CreateNews
      output: api.News<true, true>
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/orders').config<{
      output: api.WithOrderFacets<api.Pageable<api.OrderList>>
    }>({
      withToken: true,
    }),
    endpoint('POST /projects/:id/orders').config<{
      input: api.CreateOrder
      output: api.Order
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/publish').config<{
      input: api.ProjectPublishRequest
      output: api.Project
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/referrals').config<{
      output: api.ReferralListPage
    }>({
      withToken: true,
    }),
    endpoint('POST /projects/:id/refresh/:provider').config<{
      output: api.Project
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/rewards').config<{
      output: api.Reward[]
    }>({}),
    endpoint('POST /projects/:id/rewards').config<{
      input: api.CreateReward
      output: api.Reward
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/send-referral-summary').config<{
      input: {
        referral_user_id: number
      }
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/shipping-zones').config<{
      input: api.CreateShippingZone
      output: api.ShippingZones
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/submit').config<{
      input: api.ProjectSubmitRequest
      output: api.Project
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/subscriptions').config<{
      input: api.CreateSubscription
      output: api.Subscription
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/supporters').config<{
      output: api.Pageable<api.SupporterList>
    }>({}),
    endpoint('POST /projects/:id/unlike').config({
      withToken: true,
    }),
    endpoint('POST /projects/:id/unlink-social').config<{
      input: api.UnlinkSocialConnect
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /projects/:id/withdrawals').config<{
      output: api.Withdrawal
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /projects/:id/withdrawals').config<{
      output: api.Pageable<api.WithdrawalPage<boolean>>
    }>({
      withToken: true,
    }),

    // PROPOSALS
    endpoint('POST /proposals').config<{
      input: api.CreateProposal | api.CreateAnonymousProposal
      output: api.Proposal
    }>({
      type: 'json',
    }),
    endpoint('GET /proposals/:id').config<{
      output: api.Proposal
    }>({}),
    endpoint('PATCH /proposals/:id').config<{
      input: api.UpdateProposal
      output: api.Proposal
    }>({
      type: 'json',
    }),
    endpoint('POST /proposals/:id/refuse').config<{
      input: api.UpdateProposal
      output: api.Proposal
    }>({
      type: 'json',
      withToken: true,
    }),

    // REVIEWS
    endpoint('PATCH /reviews/:id').config<{
      input: api.UpdateReview
      output: api.Review
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('DELETE /reviews/:id').config({
      withToken: true,
    }),

    // REWARDS
    endpoint('GET /rewards/:id').config<{
      output: api.Variant
    }>({
      type: 'json',
      withToken: true,
    }),
    endpoint('PATCH /rewards/:id').config<{
      input: api.UpdateReward
      output: api.Reward
    }>({
      type: 'json',
      withToken: true,
    }),
    endpoint('DELETE /rewards/:id').config({
      withToken: true,
    }),
    endpoint('POST /rewards/:id/reviews').config<{
      input: api.CreateReview
      output: api.Review
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('PATCH /rewards/:id/delivery').config<{
      input: api.UpdateDelivery
      output: api.Delivery
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /rewards/shopify-:id/reviews').config<{
      output: api.Pageable<api.ProductReviewPage>
    }>({}),
    endpoint('GET /rewards/:id/shippings').config<{
      output: api.RewardShipping
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('PATCH /rewards/:id/shippings').config<{
      input: api.CreateRewardShipping
      output: api.RewardShipping
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('DELETE /rewards/:id/shippings').config<{}>({
      withToken: true,
    }),

    // SEARCH
    endpoint('GET /search/projects').config<{
      output: api.Pageable<api.ProjectList>
    }>({}),

    // SDGS
    endpoint('GET /sdgs').config<{
      output: api.SDGList
    }>({}),

    // SHIPPING RATES
    endpoint('POST /shipping-rates').config<{
      input: api.ShippingRateRequest
      output: api.ShippingRateList
    }>({
      type: 'json',
    }),

    // SHIPPING ZONES
    endpoint('DELETE /shipping-zones/:id').config({
      withToken: true,
    }),
    endpoint('PATCH /shipping-zones/:id').config<{ input: api.UpdateShippingZone; output: api.ShippingZones }>({
      withToken: true,
      type: 'json',
    }),

    // SLUGS
    endpoint('DELETE /slugs/:id').config({
      withToken: true,
    }),

    // SUBSCRIPTIONS
    endpoint('POST /subscriptions/:id/cancel').config<{
      output: api.Subscription
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /subscriptions/:id/exports').config({
      withToken: true,
    }),
    endpoint('GET /subscriptions/:id/orders').config<{
      output: api.Pageable<api.OrderList<false>>
    }>({
      withToken: true,
    }),

    // TAGS
    endpoint('GET /tags').config<{
      output: api.Pageable<api.TagList>
    }>({}),

    // THREADS
    endpoint('GET /threads/:id').config<{
      output: api.DiscussionThread
    }>({
      withToken: true,
    }),

    // USERS
    endpoint('GET /users/:id').config<{
      output: api.PublicUser
    }>({}),
    endpoint('PATCH /users/:id').config<{
      input: api.UpdateUser
      output: api.AuthenticatedUser
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('DELETE /users/:id').config<{
      input: api.DeleteUser
      output: api.DeleteUserResponse
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('POST /users/:id/accounts').config<{
      input: api.CreateAccount
      output: api.Account
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /users/:id/accounts').config<{
      output: api.Pageable<{ accounts: api.Account[] | null }>
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /users/:id/addresses').config<{
      output: api.Addresses
    }>({
      withToken: true,
    }),
    endpoint('POST /users/:id/addresses').config<{
      input: api.CreateAddress
      output: api.Address
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('PATCH /users/:id/avatar').config<{
      input: { image: File }
    }>({
      withToken: true,
      type: 'formdata',
    }),
    endpoint('POST /users/:id/images').config<{
      input: api.CreateImage
      output: { id: number; versions?: Record<string, api.ImageVersion> }
    }>({
      withToken: true,
      type: 'formdata',
    }),
    endpoint('GET /users/:id/orders').config<{
      output: api.Pageable<api.OrderList<true>>
    }>({
      withToken: true,
    }),
    endpoint('GET /users/:id/projects').config<{
      output: api.Pageable<api.ProjectList>
    }>({}),
    endpoint('POST /users/:id/proposals').config<{
      input: api.CreateProposal
      output: api.Proposal
    }>({
      withToken: true,
      type: 'json',
    }),
    endpoint('GET /users/:id/reviews').config<{
      output: api.Pageable<api.ReviewList>
    }>({
      withToken: true,
    }),
    endpoint('POST /users/social/unlink').config<{
      input: api.UnlinkSocialConnect
    }>({
      withToken: true,
      type: 'json',
    }),
  ],
  deprecated: [
    endpoint('POST /projects/:id/videos').config<{
      input: api.CreateProjectVideo
      output: api.VideoResource
    }>({
      withToken: true,
      type: 'json',
    }),
  ],
})({
  version: '2023-09-12',
  baseUrl: UFE.ROOT_API_URL,
  getAccessToken: () => {
    if (UFE.ACCESS_TOKEN_COOKIE_NAME === undefined) {
      throw Error('No access token cookie name found')
    }
    return getCookie(UFE.ACCESS_TOKEN_COOKIE_NAME)
  },
  getRefreshToken: () => {
    if (UFE.REFRESH_TOKEN_COOKIE_NAME === undefined) {
      throw Error('No refresh token cookie name found')
    }
    return getCookie(UFE.REFRESH_TOKEN_COOKIE_NAME)
  },
  persistAccessToken: (token) => {
    if (UFE.ACCESS_TOKEN_COOKIE_NAME === undefined) {
      throw Error('No access token cookie name found')
    }
    return setCookie(UFE.ACCESS_TOKEN_COOKIE_NAME, token)
  },
  refreshAccessToken: djangoOauth2,
})
